import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { sendGetRestRequest } from "../../config/axiosInstance";

const initialState = {
  data: {},
  status: {
    security: "idle",
    dataStorage: "idle",
    active: "idle",
    inActive: "idle",
    userLicense: "idle",
    UserLocation: "idle",
    socket: "idle",
    codecov: "idle",
    codeQuality: "idle",
    securityScore:"idel",
    report:"idel",
    dashboard:"idel",
    staticresource:"idel",
    salesforcesite:'idel',
    installedpackage:"idel",
    customlabel:"idel",
    processbuilder:"idel",
    emailtemplate:"idel",
    recordtype:"idel",
    community:"idel",
    remotesite:"idel",
    user:"idel",
    role:"idel",
    profile:"idel",
    permissionset:"idel",
    customobject:"idel",
    metadataScore:"idel"
  },
  loading: false,
  error: "",
};

//This Is An Action For Login
export const LoginAuth = createAsyncThunk(
  "salesforce/login",
  async (apiparams) => {
    const response = await sendGetRestRequest(
      `/oauth2/auth?environment=${apiparams}`
    );
    return response.data;
  }
);

//This Is An Action For Code Coverage
export const CodeCoverageData = createAsyncThunk(
  "salesforce/codeCoverage",
  async () => {
    const response = await sendGetRestRequest(
      `/api/v1/dashbord/codeQualityScore`
    );
    return response.data;
  }
);
//this is an Action For Security Score
export const SecurityScore = createAsyncThunk(
  "salesforce/SecurityScore",
  async () => {
    const response = await sendGetRestRequest(`/api/v1/dashbord/securityCount`);
    return response.data;
  }
);
//this is an Action For All Storage Data
export const DataStorage = createAsyncThunk(
  "salesforce/allDataStorage",
  async () => {
    const response = await sendGetRestRequest(`/api/v1/dashbord/limits`);
    return response.data;
  }
);
export const ActiveUser = createAsyncThunk(
  "salesforce/Activeuser",
  async () => {
    const response = await sendGetRestRequest(
      `/api/v1/dashbord/activeLoginHistorys`
    );
    return response.data;
  }
);

export const InActiveUser = createAsyncThunk(
  "salesforce/InActiveuser",
  async () => {
    const response = await sendGetRestRequest(
      `/api/v1/dashbord/inActiveLoginHistorys`
    );
    return response.data;
  }
);
export const UserLicense = createAsyncThunk(
  "salesforce/UserLicense",
  async () => {
    const response = await sendGetRestRequest(`/api/v1/dashbord/userLicense`);
    return response.data;
  }
);
export const SalesforceUserLicense = createAsyncThunk(
  "salesforce/SalesforceLicense",
  async () => {
    const response = await sendGetRestRequest(
      `/api/v1/dashbord/salesforceLicense`
    );
    return response.data;
  }
);
export const ActiveUserLocation = createAsyncThunk(
  "salesforce/Activeuserlocation",
  async () => {
    const response = await sendGetRestRequest(
      `/api/v1/dashbord/activeUserLocation`
    );
    return response.data;
  }
);
export const ActionForSocket = createAsyncThunk(
  "salesforce/SocketAction",
  async () => {
    const response = await sendGetRestRequest(`/api/v1/action`);
    return response.data;
  }
);

export const CodeQualityScore = createAsyncThunk(
  "salesforce/CodeQualityScore",
  async () => {
    const response = await sendGetRestRequest(`/api/v1/pmd`);
    return response.data;
  }
);

export const SecurityData = createAsyncThunk(
  "salesforce/Security",
  async () => {
    const response = await sendGetRestRequest(`/api/v1/error/security`);
    return response.data;
  }
);
///////////////////////Meta Data Section /////////////////////////////////////////////
export const ReportData = createAsyncThunk(
  "salesforce/report",
  async () => {
    const response = await sendGetRestRequest(`/api/v1/metadata/report`);
    return response.data;
  }
);
export const DashboardData = createAsyncThunk(
  "salesforce/dashboard",
  async () => {
    const response = await sendGetRestRequest(`/api/v1/metadata/dashboard`);
    return response.data;
  }
);
export const StaticData = createAsyncThunk(
  "salesforce/staticresource",
  async () => {
    const response = await sendGetRestRequest(`/api/v1/metadata/staticresource`);
    return response.data;
  }
);
export const siteData = createAsyncThunk(
  "salesforce/salseforcesite",
  async () => {
    const response = await sendGetRestRequest(`/api/v1/metadata/site`);
    return response.data;
  }
);

export const installedData = createAsyncThunk(
  "salesforce/installedpackage",
  async () => {
    const response = await sendGetRestRequest(`/api/v1/metadata/installedpackage`);
    return response.data;
  }
);
export const lableData = createAsyncThunk(
  "salesforce/customLable",
  async () => {
    const response = await sendGetRestRequest(`/api/v1/metadata/customlabel`);
    return response.data;
  }
);
export const processbuilderData = createAsyncThunk(
  "salesforce/processBuilder",
  async () => {
    const response = await sendGetRestRequest(`/api/v1/metadata/processbuilder`);
    return response.data;
  }
);
export const emailtemplateData = createAsyncThunk(
  "salesforce/emailtemplate",
  async () => {
    const response = await sendGetRestRequest(`/api/v1/metadata/emailtemplate`);
    return response.data;
  }
);
export const recordtypeData = createAsyncThunk(
  "salesforce/recordtype",
  async () => {
    const response = await sendGetRestRequest(`/api/v1/metadata/recordtype`);
    return response.data;
  }
);
export const communityData = createAsyncThunk(
  "salesforce/salesforcecommunity",
  async () => {
    const response = await sendGetRestRequest(`/api/v1/metadata/community`);
    return response.data;
  }
);
export const remoteData = createAsyncThunk(
  "salesforce/remoteSite",
  async () => {
    const response = await sendGetRestRequest(`/api/v1/metadata/remotesite`);
    return response.data;
  }
);
export const UserData = createAsyncThunk(
  "salesforce/user",
  async () => {
    const response = await sendGetRestRequest(`/api/v1/usersection/user`);
    return response.data;
  }
);
export const RoleData = createAsyncThunk(
  "salesforce/role",
  async () => {
    const response = await sendGetRestRequest(`/api/v1/usersection/role`);
    return response.data;
  }
);

export const profileData = createAsyncThunk(
  "salesforce/profile",
  async () => {
    const response = await sendGetRestRequest(`/api/v1/usersection/profile`);
    return response.data;
  }
);
export const permissionsetData = createAsyncThunk(
  "salesforce/permissionset",
  async () => {
    const response = await sendGetRestRequest(`/api/v1/usersection/permissionset`);
    return response.data;
  }
);
export const customObjectData = createAsyncThunk(
  "salesforce/customobject",
  async () => {
    const response = await sendGetRestRequest(`/api/v1/metadata/customobject`);
    return response.data;
  }
);
export const metadata = createAsyncThunk(
  "salesforce/metadataScore",
  async () => {
    const response = await sendGetRestRequest(`/api/v1/metadata/metadatascore`);
    return response.data;
  }
);

//This Is An Reducer For All
const LoginSlice = createSlice({
  name: "loginSalesforce",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(LoginAuth.pending, (state) => {
        state.status = "loading";
      })
      .addCase(LoginAuth.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.logindata = action.payload;
      })
      .addCase(LoginAuth.rejected, (state, action) => {
        state.status = "idle";
        state.data.logindata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })
      //This is Reducer For Code Quality
      .addCase(CodeCoverageData.pending, (state) => {
        state.status.codecov = "pending";
      })
      .addCase(CodeCoverageData.fulfilled, (state, action) => {
        state.status.codecov = "fulfilled";
        state.data.codeqty = action.payload;
      })
      .addCase(CodeCoverageData.rejected, (state, action) => {
        state.status.codecov = "rejected";
        state.data.codeqty = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })
      //This is Reducer For Security Score
      .addCase(SecurityScore.pending, (state) => {
        state.status.securityScore = "pending";
      })
      .addCase(SecurityScore.fulfilled, (state, action) => {
        state.status.securityScore = "fulfilled";
        state.data.securitydata = action.payload;
      })
      .addCase(SecurityScore.rejected, (state, action) => {
        state.status.securityScore = "rejected";
        state.data.securitydata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })
      //This is Reducer For Data Score
      .addCase(DataStorage.pending, (state) => {
        state.status.dataStorage = "pending";
      })
      .addCase(DataStorage.fulfilled, (state, action) => {
        state.status.dataStorage = "fullfilled";
        state.data.storagedata = action.payload;
      })
      .addCase(DataStorage.rejected, (state, action) => {
        state.status.dataStorage = "rejected";
        state.data.storagedata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })
      //This is Reducer For Active User
      .addCase(ActiveUser.pending, (state) => {
        state.status.active = "pending";
      })
      .addCase(ActiveUser.fulfilled, (state, action) => {
        state.status.active = "fulfilled";
        state.data.activeData = action.payload;
      })
      .addCase(ActiveUser.rejected, (state, action) => {
        state.status.active = "rejected";
        state.data.activeData = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })
      //This is Reducer For InActive User
      .addCase(InActiveUser.pending, (state) => {
        state.status.inActive = "pending";
      })
      .addCase(InActiveUser.fulfilled, (state, action) => {
        state.status.inActive = "fulfilled";
        state.data.inactiveData = action.payload;
      })
      .addCase(InActiveUser.rejected, (state, action) => {
        state.status.inActive = "rejected";
        state.data.inactiveData = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })
      //This is Reducer For User License
      .addCase(UserLicense.pending, (state) => {
        state.status.userLicense = "pending";
      })
      .addCase(UserLicense.fulfilled, (state, action) => {
        state.status.userLicense = "fulfilled";
        state.data.licensedata = action.payload;
      })
      .addCase(UserLicense.rejected, (state, action) => {
        state.status.userLicense = "rejected";
        state.data.licensedata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })
      //This is Reducer For Salesforce License
      .addCase(SalesforceUserLicense.pending, (state) => {
        state.status.userLicense = "pending";
      })
      .addCase(SalesforceUserLicense.fulfilled, (state, action) => {
        state.status.userLicense = "fulfilled";
        state.data.salesforcelicensedata = action.payload;
      })
      .addCase(SalesforceUserLicense.rejected, (state, action) => {
        state.status.userLicense = "rejected";
        state.data.salesforcelicensedata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })
      //This is Reducer For active User Location
      .addCase(ActiveUserLocation.pending, (state) => {
        state.status.UserLocation = "pending";
      })
      .addCase(ActiveUserLocation.fulfilled, (state, action) => {
        state.status.UserLocation = "fulfilled";
        state.data.Userlocation = action.payload;
      })
      .addCase(ActiveUserLocation.rejected, (state, action) => {
        state.status.UserLocation = "rejected";
        state.data.Userlocation = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })
      //This is Reducer For Socket Action
      .addCase(ActionForSocket.pending, (state) => {
        state.status.socket = "pending";
      })
      .addCase(ActionForSocket.fulfilled, (state, action) => {
        state.status.socket = "fulfilled";
        state.data.SocketData = action.payload;
      })
      .addCase(ActionForSocket.rejected, (state, action) => {
        state.status.socket = "rejected";
        state.data.SocketData = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })
      //This is Reducer For Code Quality Score
      .addCase(CodeQualityScore.pending, (state) => {
        state.status.codeQuality = "pending";
      })
      .addCase(CodeQualityScore.fulfilled, (state, action) => {
        state.status.codeQuality = "fulfilled";
        state.data.CodeQualityData = action.payload;
      })
      .addCase(CodeQualityScore.rejected, (state, action) => {
        state.status.codeQuality = "rejected";
        state.data.CodeQualityData = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })
      //this is Reducer For Security Data
      .addCase(SecurityData.pending, (state) => {
        state.status.security = "pending";
      })
      .addCase(SecurityData.fulfilled, (state, action) => {
        state.status.security = "fulfilled";
        state.data.security = action.payload;
      })
      .addCase(SecurityData.rejected, (state, action) => {
        state.status.security = "rejected";
        state.data.security = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })
      /////////////////Meta-Data Reducder///////////////////////////////
      .addCase(ReportData.pending, (state) => {
        state.status.report = "pending";
      })
      .addCase(ReportData.fulfilled, (state, action) => {
        state.status.report = "fulfilled";
        state.data.reportData = action.payload;
      })
      .addCase(ReportData.rejected, (state, action) => {
        state.status.report = "rejected";
        state.data.reportData = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })
      ////////////////////////Dashbooard////////////////////
      .addCase(DashboardData.pending, (state) => {
        state.status.dashboard = "pending";
      })
      .addCase(DashboardData.fulfilled, (state, action) => {
        state.status.dashboard = "fulfilled";
        state.data.dashboardData = action.payload;
      })
      .addCase(DashboardData.rejected, (state, action) => {
        state.status.dashboard = "rejected";
        state.data.reportData = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      }) ////////////////////////static Resource////////////////////
      .addCase(StaticData.pending, (state) => {
        state.status.staticresource = "pending";
      })
      .addCase(StaticData.fulfilled, (state, action) => {
        state.status.staticresource = "fulfilled";
        state.data.staticresource = action.payload;
      })
      .addCase(StaticData.rejected, (state, action) => {
        state.status.staticresource = "rejected";
        state.data.staticresource = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })////////////////////////Salseforce Site////////////////////
      .addCase(siteData.pending, (state) => {
        state.status.salesforcesite = "pending";
      })
      .addCase(siteData.fulfilled, (state, action) => {
        state.status.salesforcesite = "fulfilled";
        state.data.salesforcesitedata = action.payload;
      })
      .addCase(siteData.rejected, (state, action) => {
        state.status.salesforcesite = "rejected";
        state.data.salesforcesitedata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })
      ////////////////////////Insalled Package////////////////////
      .addCase(installedData.pending, (state) => {
        state.status.installedpackage = "pending";
      })
      .addCase(installedData.fulfilled, (state, action) => {
        state.status.installedpackage = "fulfilled";
        state.data.installeddata = action.payload;
      })
      .addCase(installedData.rejected, (state, action) => {
        state.status.installedpackage = "rejected";
        state.data.installeddata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })
      ////////////////////////Custom Label////////////////////
      .addCase(lableData.pending, (state) => {
        state.status.customlabel = "pending";
      })
      .addCase(lableData.fulfilled, (state, action) => {
        state.status.customlabel = "fulfilled";
        state.data.customlabelData= action.payload;
      })
      .addCase(lableData.rejected, (state, action) => {
        state.status.customlabel = "rejected";
        state.data.customlabelData = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })
      ////////////////////////process Builder////////////////////
      .addCase(processbuilderData.pending, (state) => {
        state.status.processbuilder = "pending";
      })
      .addCase(processbuilderData.fulfilled, (state, action) => {
        state.status.processbuilder = "fulfilled";
        state.data.processBuilderData= action.payload;
      })
      .addCase(processbuilderData.rejected, (state, action) => {
        state.status.processbuilder = "rejected";
        state.data.processBuilderData = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })
      ////////////////////////Email Template////////////////////
      .addCase(emailtemplateData.pending, (state) => {
        state.status.emailtemplate = "pending";
      })
      .addCase(emailtemplateData.fulfilled, (state, action) => {
        state.status.emailtemplate = "fulfilled";
        state.data.emailtemplateData= action.payload;
      })
      .addCase(emailtemplateData.rejected, (state, action) => {
        state.status.emailtemplate = "rejected";
        state.data.emailtemplateData = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })
         ////////////////////////record Type////////////////////
         .addCase(recordtypeData.pending, (state) => {
          state.status.recordtype = "pending";
        })
        .addCase(recordtypeData.fulfilled, (state, action) => {
          state.status.recordtype = "fulfilled";
          state.data.recordTypeData= action.payload;
        })
        .addCase(recordtypeData.rejected, (state, action) => {
          state.status.recordtype = "rejected";
          state.data.recordTypeData = {
            status: "failed ",
            message: `${action.error.name} : ${action.error.message}`,
          };
        })
         //////////////////////// Salesforce Community ////////////////////
         .addCase(communityData.pending, (state) => {
          state.status.community = "pending";
        })
        .addCase(communityData.fulfilled, (state, action) => {
          state.status.community = "fulfilled";
          state.data.salesforceCommunityData= action.payload;
        })
        .addCase(communityData.rejected, (state, action) => {
          state.status.community = "rejected";
          state.data.salesforceCommunityData = {
            status: "failed ",
            message: `${action.error.name} : ${action.error.message}`,
          };
        })
          //////////////////////// Remote Site ////////////////////
          .addCase(remoteData.pending, (state) => {
            state.status.remotesite = "pending";
          })
          .addCase(remoteData.fulfilled, (state, action) => {
            state.status.remotesite = "fulfilled";
            state.data.remotesiteData= action.payload;
          })
          .addCase(remoteData.rejected, (state, action) => {
            state.status.remotesite = "rejected";
            state.data.remotesiteData = {
              status: "failed ",
              message: `${action.error.name} : ${action.error.message}`,
            };
          })
          //////////////////////User Section/////////////////////////////////
          .addCase(UserData.pending, (state) => {
            state.status.user = "pending";
          })
          .addCase(UserData.fulfilled, (state, action) => {
            state.status.user = "fulfilled";
            state.data.UserData= action.payload;
          })
          .addCase(UserData.rejected, (state, action) => {
            state.status.user = "rejected";
            state.data.UserData = {
              status: "failed ",
              message: `${action.error.name} : ${action.error.message}`,
            };
          })
           //////////////////////Role Section/////////////////////////////////
           .addCase(RoleData.pending, (state) => {
            state.status.role = "pending";
          })
          .addCase(RoleData.fulfilled, (state, action) => {
            state.status.role = "fulfilled";
            state.data.roleData= action.payload;
          })
          .addCase(RoleData.rejected, (state, action) => {
            state.status.role = "rejected";
            state.data.roleData = {
              status: "failed ",
              message: `${action.error.name} : ${action.error.message}`,
            };
          })
              //////////////////////Profile Section/////////////////////////////////
              .addCase(profileData.pending, (state) => {
                state.status.profile = "pending";
              })
              .addCase(profileData.fulfilled, (state, action) => {
                state.status.profile = "fulfilled";
                state.data.profileData= action.payload;
              })
              .addCase(profileData.rejected, (state, action) => {
                state.status.profile = "rejected";
                state.data.profileData = {
                  status: "failed ",
                  message: `${action.error.name} : ${action.error.message}`,
                };
              })
                 //////////////////////Profile Section/////////////////////////////////
                 .addCase(permissionsetData.pending, (state) => {
                  state.status.permissionset = "pending";
                })
                .addCase(permissionsetData.fulfilled, (state, action) => {
                  state.status.permissionset = "fulfilled";
                  state.data.permissionsetData= action.payload;
                })
                .addCase(permissionsetData.rejected, (state, action) => {
                  state.status.permissionset = "rejected";
                  state.data.permissionsetData = {
                    status: "failed ",
                    message: `${action.error.name} : ${action.error.message}`,
                  };
                })
                //////////////////////Custom Object Section/////////////////////////////////
                .addCase(customObjectData.pending, (state) => {
                  state.status.customobject = "pending";
                })
                .addCase(customObjectData.fulfilled, (state, action) => {
                  state.status.customobject = "fulfilled";
                  state.data.customobjectData= action.payload;
                })
                .addCase(customObjectData.rejected, (state, action) => {
                  state.status.customobject = "rejected";
                  state.data.customobjectData = {
                    status: "failed ",
                    message: `${action.error.name} : ${action.error.message}`,
                  };
                })
                //////////////////////MetaData Score Section/////////////////////////////////
                .addCase(metadata.pending, (state) => {
                  state.status.metadataScore = "pending";
                })
                .addCase(metadata.fulfilled, (state, action) => {
                  state.status.metadataScore = "fulfilled";
                  state.data.metaDataScore= action.payload;
                })
                .addCase(metadata.rejected, (state, action) => {
                  state.status.metadataScore = "rejected";
                  state.data.metaDataScore = {
                    status: "failed ",
                    message: `${action.error.name} : ${action.error.message}`,
                  };
                })
                
  },
});

export default LoginSlice.reducer;
